export const cardsData = [
    {
        "index": "1",
        "alt": "Transfer Wise Company Logo",
        "title": "Hackathon Budapest 2022, Wise Challenge",
        "longDes": "This project was created under a hackathon, where we took the challenge from Wise. In this challenge the focus was on 'How to help the customers (of Wise) to save more money?'. Under the next 48 hours we implemented some new features to help this cause. The application was built with Node.js and Flowbite (components built with Tailwind), me and one of my teammates were creating the front-end. Our idea was to gamify the money saving process, you could set a monthly goal on how much to save and if you reach that limit you would get so called 'Wise Tokens' that later could be used to get discounts in certain stores or restaurants. We could implement every idea we had, you can watch a short demo about this in the video below. Sadly we don't have the application up and running since the database got taken down after the lack of usage.",
        "link": [],
        "icon": "fa-solid fa-arrow-down fa-2xl",
        "moreIcons": [],
        "moreLink": [],
        "video": "https://youtube.com/embed/Ixo4LV1nGrg"
    },
    {
        "index": "2",
        "alt": "Slingshot Effect Mobile Game Screenshot",
        "title": "Mobile Game Developed With Unity",
        "longDes": "This mobile game I published to Google Play was made in Unity game engine. My intention was to practice game development as it became on of my hobbies after getting a game making exercise as homework in university. Except the 3D models everything is made by me in the game, which was quite a big challenge to overcome. I completed the project in about two months and ever since it is available on Google Play. If you would like to give it a spin click the link below, it is available for Android, Windows, Linux and Mac OS as well.",
        "link": ["https://play.google.com/store/apps/details?id=com.DextersGames.SlingshotEffect&hl=en", "Slingshot Effect Download Mobile"],
        "icon": "fa-brands fa-google-play",
        "moreIcons": ["fa-brands fa-windows","fa-brands fa-apple","fa-brands fa-linux"],
        "moreLink": ["https://dexters-games.itch.io/slingshot-effect", "Slingshot Effect Download PC"],
        "video": "https://youtube.com/embed/18g-ztJYOMc"
    },
    {
        "index": "3",
        "alt": "3D humanoid character walking with the help of AI",
        "title": "Procedural Animation With The Help Of Neural Networks",
        "longDes": "In this project I was able to create real time animation with the help of neural networks in Unity. In order to achieve this every part of the 3D humanoid model was physically simulated. Forces were applied to the joints of the character, which acted as muscles to move it around. The neural network during learning was able to set the direction and the force of each limb's movement. After setting some rules on the movement the character started to run more or less like a human. Its goal was to reach the green box at the end of the platform as soon as it can, as you can see in the video below it could do it comfortably every time after the 30 hours of learning period. With this project I also took 3rd place in the Scientific Student’s Conference in Óbuda University.",
        "link": [],
        "icon": "fa-solid fa-arrow-down fa-2xl",
        "moreIcons": [],
        "moreLink": [],
        "video": "https://youtube.com/embed/EtWR-67OvcM"
    },
    {
        "index": "4",
        "alt": "Logo of the website called Agile With Aigerim",
        "title": "Website Of An Agile Consultant",
        "longDes": "One of my colleagues apporoched me to create her a website, since she is launching an agile coach business. The goal here was to show every useful information about her course and consultation in a modern way. In this project I used React, Framer Motion for the animations, Three.js to embed the 3D model and Material UI since I had to be quick making this website. The site is deployed on github pages, it is up and running, you can check it out on the below link.",
        "link": ["https://agilewithaigerim.com/", "www.agilewithaigerim.com"],
        "icon": "fa-solid fa-book",
        "moreIcons": [],
        "moreLink": [],
        "video": ""
    }
]

export default {
    cardsData,
}