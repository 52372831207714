import Home from './components/pages/home.js'
import './App.css';

function App() {
  return (
    <>
      <Home />
    </>
  );
}

export default App;